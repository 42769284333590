* {
  box-sizing: border-box;
}

body {
  text-align: center;
  background-color: #eeeeee;
}

.Row {
  display: flex;
  flex-flow: row wrap;
}

.Row-letter {
  margin: 2px;
  border: 2px solid rgba(128, 128, 128, 0.8);
  flex: 1 1;
  max-width: 30px;
  min-width: 30px;
  height: 30px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  transition: all .3s ease-out;
}


.Row-letter.letter-space {
  max-width: 30px;
  width: 3px;
  border: none;
  margin: 1%;
  min-width: 15px;
}

.Row-letter-color {
  margin: 2px;
  border: 2px solid rgba(128, 128, 128, 0.8);
  flex: 1;
  max-width: 40px;
  min-width: 30px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}

.Row-annotation {
  margin-inline-start: 16px;
  width: 5em;
  text-align: start;
}

.App-container {
  position: relative;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
}

.App-container h1 {
  margin-top: 0;
}

.Game,
h1 {
  user-select: none;
}

.Game {
  display: flex;
  flex-direction: column;
}

table.Game-rows {
  margin: auto;
  display: flex;
  flex-direction: column;
}

table.Game-rows:focus {
  outline: none;
}

table.Game-rows>tbody {
  display: flex;
  flex-direction: column;
}

.Game-keyboard {
  display: flex;
  flex-direction: column;
}

.Game-keyboard-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.Game-keyboard-button {
  margin: 2px;
  background-color: #cdcdcd;
  padding: 2px;
  text-transform: capitalize;
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: 20px;
  color: inherit;
  text-decoration: inherit;
  border: 2px solid transparent;
  cursor: pointer;
}

.Game-keyboard-button-wide {
  flex: 2;
}

.Game-keyboard-button:focus {
  outline: none;
}

.word {
  max-height: 40px;
  /* max-width: fit-content; */
  flex: 1 1;
  display: flex;
}

@keyframes TransitioningBackground {
  0% {
    background-position: 26% 98%;
  }

  50% {
    background-position: 27% 96%;
  }

  100% {
    background-position: 26% 98%;
  }
}

.buttons button {
  margin: 0 5px;
}

.letter-correct {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: rgb(24, 137, 68);
  background-image: linear-gradient(45deg, green, green, gold, green, green);
  background-size: 400% 400%;
  animation: TransitioningBackground 4s ease infinite;
  color: white !important;
}

.letter-hidden {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: #eee;
  color: #eee !important;
}

.letter-punctuation {
  border: none;
  background-color: #eee;
  color: rgb(0, 0, 0) !important;
  max-width: fit-content;
  min-width: 0px;
}

.letter-absent {
  border: 2px solid rgb(162, 162, 162);
  background: rgb(162, 162, 162);
  color: white !important;
}

.letter-fade0 {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: #1b6f2c;
  color: rgba(255, 255, 255, 0.99) !important;
}

.letter-fade1 {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: #32b411;
  color: rgba(255, 255, 255, 0.98) !important;
}

.letter-fade2 {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: #77b61d;
  color: rgba(255, 255, 255, 0.95) !important;
}

.letter-fade3 {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: #c6a736;
  color: rgba(255, 255, 255, 0.93) !important;
}

.letter-fade4 {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: #F00;
  color: rgba(255, 255, 255, 0.89) !important;
}

body.dark {
  background-color: #404040;
  color: #e0e0e0;
  color-scheme: dark;
}

body.dark .Game-keyboard-button {
  color: #404040;
}

a,
a:visited {
  color: #8080ff;
}

a:active {
  color: #cc77ff;
}

.Game-options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.Game-options>*+* {
  margin-inline-start: 0.5rem;
}

.Game-options button {
  min-width: 4rem;
}

.App-footer {
  margin: -1rem 0 2rem 0;
  font-size: 80%;
  line-height: 1.5;
}

.App-about {
  margin-top: -1rem;
  line-height: 1.4;
}

.App-about b {
  background-color: #888;
  color: white;
  padding: 1px 3px;
  border-radius: 2px;
}

.App-about b.green-bg {
  background-color: rgb(87, 172, 120);
}

.App-about b.yellow-bg {
  background-color: #e9c601;
}

.Game-sr-feedback,
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.Settings {
  text-align: left;
  font-size: 18px;
}

.Settings-setting {
  margin: 8px;
  display: flex;
  align-items: top;
}

.Settings-setting input {
  width: 18px;
  height: 18px;
}

.Settings-setting input[type="range"] {
  width: 50px;
  height: 18px;
}

.Settings-setting label {
  margin-inline-start: 8px;
  margin-inline-end: 8px;
}

.top-left {
  position: absolute;
  top: 5px;
  left: 5px;
}

.top-left button {
  margin: 10px 5px 0 0;
}

.top-right {
  position: absolute;
  top: 5px;
  right: 5px;
}

.emoji-link {
  font-size: 24px;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.top-right>*+* {
  margin-inline-start: 8px;
}

.top-left>*+* {
  margin-inline-start: 8px;
}

.App-container.color-blind .letter-correct,
.App-container.color-blind .App-about b.green-bg {
  background-color: #f5793a;
}

.App-container.color-blind .letter-elsewhere,
.App-container.color-blind .App-about b.yellow-bg {
  background-color: #85c0f9;
}

#x {
  position: absolute;
  right: 2%;
}

#chartHolder {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background: #dddd;
  z-index: 10000;
}

#chartHolderInner {
  position: absolute;
  width: 90%;
  background: radial-gradient(#fff, #fffffff0);
  top: 12%;
  border-radius: 48px;
  margin: 30px 5%;
  padding: 30px;
}

#myCanvas {
  border-radius: 15px;
}

@media only screen and (max-width: 450px),
only screen and (max-height: 900px) {
  .Row-letter {
    max-width: 28px;
    min-width: 28x;
    height: 28px;
    font-size: 21px;
  }

  .word.long .Row-letter {
    max-width: 25px;
    min-width: 25px;
  }

  .Game-keyboard-button {
    min-height: 38px;
  }

  p {
    margin: 0.35em;
  }

  h1 {
    margin-bottom: 0.35em
  }
}

@media only screen and (max-width: 400px) and (max-height: 800px) {
  .Row-letter {
    max-width: 25px;
    min-width: 25px;
    height: 26px;
    font-size: 20px;
  }

  .word.long .Row-letter {
    max-width: 22px;
    min-width: 22px;
  }

  .Game-keyboard-button {
    min-height: 37px;
  }

  p {
    margin: 0.25em;
  }

  h1 {
    margin-bottom: 0.25em
  }
}